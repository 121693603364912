import React from "react";

import { graphql, Link } from "gatsby";
import BackgroundImage from 'gatsby-background-image';
import Layout from "../components/layout";
import SEO from "../components/seo";
import Img from 'gatsby-image';

const OneOnOneExecutiveCoaching = ({ data }) => {
  const title = "One to One Coaching";
  const imageData = data.file.childImageSharp.fluid;
  const infoGraphicFluid = data.infoGraphic.childImageSharp.fluid;
  return (
    <Layout headerClass="relative bg-white" bodyClass="px-0 md:px-0 lg:px-0 h-full growing">
      <SEO title={title} />

      <div
        className="min-h-screen h-full flex flex-col "
        
      >
        <BackgroundImage
        Tag="div" className="w-full h-40 sm:h-32 lg:h-56"  fluid={imageData}>


          <div className="container mx-auto px-4 sm:px-2 md:px-20 lg:px-24 mb-auto">
            <div className="w-7/12 mt-10 sm:mt-12 lg:mt-20 font-heading self-start">
              <h1 className="text-4xl md:text-3xl lg:text-6xl text-pink-600 leading-tight text-shadow-xl">{title}</h1>
            </div>
            </div>
            

        </BackgroundImage>
        <div className="container mx-auto pb-10  md:px-20 lg:px-56 px-6">
            <div className="mt-6 p-1 bg-purple-900 heropattern-texture-purple-800"> 
              <div className="text-base  self-center m-4" >
                <p className="text-white text-shadow-sm">One to One coaching helps you create or achieve what your version 
                of success is, which is unique to you alone</p>
              </div>
            </div>
            <div className="text-base text-teal-900">
              <ul className="p-5 list-disc">
                <li>Establish meaningful personal and career goals, at any stage of your career</li>
                <li>Improve working relationships and strengthen resilience skills, while improving self confidence and awareness</li>
                <li>Adds value to strategic lifecycle strategies by encouraging a culture of maximising each individuals performance and potential</li> 
                <li>Supports strong succession planning by nurturing leadership talent and encouraging high impact, adaptable leaders</li>
              </ul>
            </div>
        </div>
        <div className="container mx-auto px-6 md:px-10 lg:px-16 mb-5">
            <div className="flex flex-col mt-5 mb-5 items-center"> 
                <div className="text-4xl self-center">
                  <h2 className="font-heading text-teal-900">My Coaching Process</h2>
                </div>
                <div className="w-full sm:w-3/4 md:w-3/4 lg:w-1/2 xl:w-1/2">
                  <Img 
                    fluid={infoGraphicFluid} 
                    alt="Angela Cooney - Executive Coach Process - Dublin - Ireland" 
                  />
                </div>
            </div>
        </div>
      </div>

    </Layout>
  );
};

export const query = graphql`
  query {
    file(relativePath: { eq: "services-page-compass-banner.jpg"}) {
        childImageSharp {
          fluid(quality: 90, maxWidth: 1920) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      infoGraphic: file(relativePath: { eq: "coach-process-info-graphic-min.png"}) {
        childImageSharp {
          fluid( maxWidth: 320, quality: 100) {
            ...GatsbyImageSharpFluid_tracedSVG
          }
        }
      }
  }
`;


export default OneOnOneExecutiveCoaching;